import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Building2, Info, FileText, Upload, Plus } from "lucide-react";
import { CompanyInfo } from "@/shared/components/company-info/models/CompanyInfo";
import { CompanySearchOverlay } from "./CompanySearchOverlay";
import { DocumentSearchOverlay } from "./DocumentSearchOverlay";
import { UploadOverlay } from "./UploadOverlay";
import { ResearchQuestionModal } from "./ResearchQuestionModal";
import { ResearchTypeModal } from "./ResearchTypeModal";
import { CompanyDocumentView } from "@/shared/models/CompanyDocument";
import UploadItem from "@/shared/models/UploadItem";
import ChatContext from "@/shared/models/ChatContext";
import PlaybookEstimateModal from "@/features/playbook/components/PlaybookEstimateModal";
import { useChatFeatures } from "@/shared/hooks/useChatFeatures";
import { UseWebSearchToggle } from "./UseWebSearchToggle";
import { UseNewsSearchToggle } from "./UseNewsSearchToggle";
import { useHasAdminRole } from "@/shared/hooks/useHasRole";
import CenterLoader from "@/shared/components/loader/CenterLoader";

interface AddContextModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: (contextData: ChatContext) => Promise<void>;
  playbookId?: string;
  isDefaultPlaybook?: boolean;
  initialNews?: boolean;
  initialWeb?: boolean;
  isExistingChat?: boolean;
}

export function AddContextModal({
  open,
  onClose,
  onComplete,
  playbookId = undefined,
  isDefaultPlaybook = false,
  initialNews = true,
  initialWeb = true,
  isExistingChat = false,
}: AddContextModalProps) {
  const [isCompanySearching, setIsCompanySearching] = useState(false);
  const [isDocumentSearching, setIsDocumentSearching] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyInfo[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    CompanyDocumentView[]
  >([]);
  const [uploadedItems, setUploadedItems] = useState<UploadItem[]>([]);
  const [includeNews, setIncludeNews] = useState(initialNews);
  const [includeWeb, setIncludeWeb] = useState(initialWeb);
  const [showResearchType, setShowResearchType] = useState(false);
  const [showResearchQuestion, setShowResearchQuestion] = useState(false);
  const [researchType, setResearchType] = useState<"chat" | "playbook">();
  const isAdmin = useHasAdminRole();
  const [showEstimateModal, setShowEstimateModal] = useState(false);
  const { features } = useChatFeatures();

  // Update state when initial values change
  useEffect(() => {
    setIncludeNews(initialNews);
    setIncludeWeb(initialWeb);
  }, [initialNews, initialWeb]);

  const addCompany = (company: CompanyInfo) => {
    if (!selectedCompanies.find((c: CompanyInfo) => c.id === company.id)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const removeCompany = (companyId: number) => {
    setSelectedCompanies(
      selectedCompanies.filter((c: CompanyInfo) => c.id !== companyId),
    );
  };

  const addDocument = (document: CompanyDocumentView) => {
    if (
      !selectedDocuments.find((d: CompanyDocumentView) => d.id === document.id)
    ) {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  };

  const removeDocument = (documentId: string) => {
    setSelectedDocuments(
      selectedDocuments.filter((d: CompanyDocumentView) => d.id !== documentId),
    );
  };

  const addUploadItem = (item: UploadItem) => {
    setUploadedItems((prev: UploadItem[]) => {
      const updatedItems = prev.filter((i: UploadItem) => i.id !== item.id);
      return [...updatedItems, item];
    });
  };

  const removeUploadItem = (itemId: number) => {
    setUploadedItems((items: UploadItem[]) =>
      items.filter((i: UploadItem) => i.id !== itemId),
    );
  };

  const handleComplete = async () => {
    try {
      setIsSubmitting(true);
      await onComplete(getContext());
      setSelectedCompanies([]);
      setSelectedDocuments([]);
      setUploadedItems([]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEstimatePlaybook = () => {
    setShowEstimateModal(true);
  };

  const handleResearchTypeSelect = (type: "chat" | "playbook") => {
    setResearchType(type);
    setShowResearchType(false);
    if (type === "chat") {
      setShowResearchQuestion(true);
    } else {
      handleComplete();
    }
  };

  const getContext = (): ChatContext => {
    const contextData = new ChatContext();
    contextData.companies = selectedCompanies;
    contextData.documents = selectedDocuments;
    contextData.uploadedItems = uploadedItems;
    contextData.includeNews = includeNews;
    contextData.includeWeb = includeWeb;
    return contextData;
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="max-w-[1200px] w-[95vw] p-0">
          <DialogTitle className="sr-only">Add Context</DialogTitle>
          <Card className="p-8 space-y-8 relative">
            <div className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-900">
                Research Context
              </h2>
              <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 flex items-start gap-3">
                <div className="text-amber-500 mt-0.5">
                  <Info className="h-5 w-5" />
                </div>
                <p className="text-sm text-amber-800 font-medium">
                  You can add multiple items to each category and combine
                  categories and sources.
                </p>
              </div>
            </div>

            {/* Main Upload Options */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Companies Section */}
              <div className="group relative">
                <Button
                  variant="outline"
                  className="w-full h-auto p-6 hover:border-blue-500 hover:bg-blue-50/50
                             flex flex-col items-center gap-4 group-hover:border-blue-500
                             min-h-[200px] relative"
                  onClick={() => setIsCompanySearching(true)}
                  disabled={isSubmitting}
                >
                  <Building2 className="h-10 w-10 text-gray-600 group-hover:text-blue-600" />
                  <div className="text-center">
                    <h3 className="font-medium mb-2 text-lg">Add Companies</h3>
                    <p className="text-sm text-gray-500">
                      Include one or multiple companies to research
                    </p>
                  </div>
                  {selectedCompanies.length > 0 && (
                    <div className="absolute top-2 right-2 bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-xs">
                      {selectedCompanies.length} added
                    </div>
                  )}
                  <Plus
                    className="h-8 w-8 absolute bottom-4 right-4 
                                text-gray-400 group-hover:text-blue-500
                                transition-all duration-200 
                                group-hover:scale-110"
                  />
                </Button>
              </div>

              {/* Documents Section */}
              <div className="group relative">
                <Button
                  variant="outline"
                  className="w-full h-auto p-6 hover:border-emerald-500 hover:bg-emerald-50/50
                             flex flex-col items-center gap-4 group-hover:border-emerald-500
                             min-h-[200px] relative"
                  onClick={() => setIsDocumentSearching(true)}
                  disabled={isSubmitting}
                >
                  <FileText className="h-10 w-10 text-gray-600 group-hover:text-emerald-600" />
                  <div className="text-center">
                    <h3 className="font-medium mb-2 text-lg">
                      Add Specific Documents
                    </h3>
                    <p className="text-sm text-gray-500">
                      Include specific documents you want to research
                    </p>
                  </div>
                  {selectedDocuments.length > 0 && (
                    <div className="absolute top-2 right-2 bg-emerald-100 text-emerald-700 px-2 py-1 rounded-full text-xs">
                      {selectedDocuments.length} added
                    </div>
                  )}
                  <Plus
                    className="h-8 w-8 absolute bottom-4 right-4 
                                text-gray-400 group-hover:text-emerald-500
                                transition-all duration-200 
                                group-hover:scale-110"
                  />
                </Button>
              </div>

              {/* Upload Section */}
              <div className="group relative">
                <Button
                  variant="outline"
                  className="w-full h-auto p-6 hover:border-purple-500 hover:bg-purple-50/50
                             flex flex-col items-center gap-4 group-hover:border-purple-500
                             min-h-[200px] relative"
                  onClick={() => setIsUploading(true)}
                  disabled={isSubmitting}
                >
                  <Upload className="h-10 w-10 text-gray-600 group-hover:text-purple-600" />
                  <div className="text-center">
                    <h3 className="font-medium mb-2 text-lg">
                      Upload Files or URLs
                    </h3>
                    <p className="text-sm text-gray-500">
                      Upload files or add URLs to include in research
                    </p>
                  </div>
                  {uploadedItems.length > 0 && (
                    <div className="absolute top-2 right-2 bg-purple-100 text-purple-700 px-2 py-1 rounded-full text-xs">
                      {uploadedItems.length} added
                    </div>
                  )}
                  <Plus
                    className="h-8 w-8 absolute bottom-4 right-4 
                                text-gray-400 group-hover:text-purple-500
                                transition-all duration-200 
                                group-hover:scale-110"
                  />
                </Button>
              </div>
            </div>

            {/* Additional Sources */}
            {(features.isInternetSearchEnabled ||
              features.isNewsSearchEnabled) && (
              <div className="border-t pt-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Additional Sources
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* News Card */}
                  {features.isNewsSearchEnabled && (
                    <UseNewsSearchToggle
                      includeNews={includeNews}
                      setIncludeNews={setIncludeNews}
                      disabled={isExistingChat || isSubmitting}
                    />
                  )}

                  {/* Web Access Card */}
                  {features.isInternetSearchEnabled && (
                    <UseWebSearchToggle
                      includeWeb={includeWeb}
                      setIncludeWeb={setIncludeWeb}
                      disabled={isExistingChat || isSubmitting}
                    />
                  )}
                </div>
              </div>
            )}

            {/* Footer */}
            <div className="flex justify-end pt-6 border-t">
              {playbookId && getContext().isEmpty && (
                <span className="mr-4 text-red-500 text-xs h-9 flex items-center">
                  Please add at least one source to continue
                </span>
              )}
              <Button
                onClick={handleComplete}
                className="bg-gray-900 hover:bg-gray-800 text-white"
                disabled={getContext().isEmpty || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <CenterLoader size={"sm"} />
                    Loading...
                  </>
                ) : (
                  "Next"
                )}
              </Button>

              {playbookId && isAdmin && (
                <Button
                  onClick={handleEstimatePlaybook}
                  className="bg-gray-600 hover:bg-gray-400 text-white ml-2"
                  disabled={getContext().isEmpty}
                >
                  Estimate
                </Button>
              )}
            </div>

            {/* Overlays */}
            <CompanySearchOverlay
              isOpen={isCompanySearching}
              onClose={() => setIsCompanySearching(false)}
              selectedCompanies={selectedCompanies}
              onCompanySelect={addCompany}
              onCompanyRemove={removeCompany}
            />

            <DocumentSearchOverlay
              isOpen={isDocumentSearching}
              onClose={() => setIsDocumentSearching(false)}
              selectedDocuments={selectedDocuments}
              onDocumentSelect={addDocument}
              onDocumentRemove={removeDocument}
            />

            <UploadOverlay
              isOpen={isUploading}
              onClose={() => setIsUploading(false)}
              selectedItems={uploadedItems}
              onItemAdd={addUploadItem}
              onItemRemove={removeUploadItem}
            />
          </Card>
        </DialogContent>
      </Dialog>

      <ResearchTypeModal
        open={showResearchType}
        onClose={() => {
          setShowResearchType(false);
          onClose();
        }}
        onSelectType={handleResearchTypeSelect}
      />

      {!playbookId && (
        <ResearchQuestionModal
          open={showResearchQuestion}
          onClose={() => {
            setShowResearchQuestion(false);
            onClose();
          }}
          context={getContext()}
          researchType={researchType}
        />
      )}

      {showEstimateModal && playbookId && !isSubmitting && (
        <PlaybookEstimateModal
          open={showEstimateModal}
          onClose={() => setShowEstimateModal(false)}
          playbookId={playbookId}
          isDefault={isDefaultPlaybook}
          context={getContext()}
          onComplete={handleComplete}
        />
      )}
    </>
  );
}
